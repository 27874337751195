

@media screen and (max-width: 2000px) {
    .w-100 {
        width: 100% !important;
        height: 80vh;
      }

}

@media screen and (max-width: 1150px) {
    .w-100 {
        width: 100% !important;
        height: 85vh;
      }
}

@media screen and (max-width: 650px) {
    .w-100 {
        width: 100% !important;
        height: 80vh;
      }

}

@media screen and (max-width: 450px) {
    .w-100 {
        width: 100% !important;
        height: 60vh;
      }


}