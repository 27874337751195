.app__specialMenu {
    flex-direction: row;
    background: var(--color-black);
}


.app__specialMenu-title {
    margin-bottom: 2rem;
    text-align: center;
}

.app__specialMenu-menu {
    width: 100%;
    margin: 2rem 0;
    
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction:column;
}

.app__specialMenu-Btn{
    width: 100%;
    margin: 2rem 0;
    
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction:column;
}

.app__specialMenu-menu_heading {
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 45px;
    line-height: 58.5px;
    letter-spacing: 0.04em;
    color: var(--color-white);
}



.app__specialMenu-menu_img {
    width: 410px;
    margin: 0 2rem;
}

.app__specialMenu-menu_img img {
    width: 100%;
    height: auto;
}


.container-menu-img {
    width: 55vw;
    height: 85vh;
}
.menu-img {
    width: 50vw;
    height: 80vh;

}

.menu__dropmenu{
    font-family: var(--font-base);
    font-size: 65px;

}



@media screen and (min-width: 2000px) {
   
    .app__specialMenu-menu_img {
        width: 750px;
    }

    .app__specialMenu-menu_img img {
        height: 920px;
    }
  
}

@media screen and (max-width: 1150px) {
    .app__specialMenu-menu {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .app__specialMenu-menu_img {
        margin: 3rem 0;
    }
    .container-menu-img {
        width: 65vw;
        height: 90vh;
    }
    .menu-img {
        width: 62vw;
        height: 85vh;

    }
}


@media screen and (max-width: 850px) {
    .app__specialMenu-menu_img {
        width: 100%;
    }

    .app__specialMenu-menu_heading {
        font-size: 45px;
        line-height: 48.5px;
    }
    .container-menu-img {
        width: 82vw;
        height: 95vh;
    }
    .menu-img {
        width: 77vw;
        height: 88vh;

    }
}

@media screen and (max-width: 650px) {
    .app__specialMenu-menu_img {
        width: 100%;
    }

    .app__specialMenu-menu_heading {
        font-size: 35px;
        line-height: 48.5px;
    }
    .container-menu-img {
        width: 85vw;
        height: 75vh;
    }
    .menu-img {
        width: 78vw;
        height: 70vh;

    }
}

    @media screen and (max-width: 450px) {
 .app__specialMenu-menu_img {
        width: 100%;
    }

    .app__specialMenu-menu_heading {
        font-size: 35px;
        line-height: 48.5px;
    }
    .container-menu-img {
        width: 85vw;
        height: 65vh;
    }
    .menu-img {
        width: 75vw;
        height: 60vh;

    }
    }

