.app__aboutus {
    position: relative;
}

.app__aboutus-overlay {
    position: absolute;
    inset: 0;
}

.app__aboutus-overlay img {
    width: 391px;
    height: 415px;
    z-index: 0;
}

.app__aboutus-content {
    width: 100%;
    z-index: 2;
}

.app__aboutus-content_about {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align:center;
}

/* .app__aboutus-content_about p {
    margin: 2rem 0;
    /* color: var(--color-grey); */
    /* opacity: 0.7; */
    
    .p__opensansabout{
        margin: 2rem 0;
        font-family: var(--font-alt);
        color: var(--color-white);
        font-weight: 400;
        letter-spacing: 0.04em;
        line-height: 28px;
        font-size: 20px;
        opacity: 0.9; 
    }

@media screen and (max-width: 2000px) {
    .app__aboutus-content_about p {
        margin: 4rem 0;
    }
    .p__opensansabout{
        
        font-size: x-large;
        line-height: 57px;
        
    }
}

@media screen and (max-width: 900px) {
    .app__aboutus-content {
        flex-direction: column;
    }
    .p__opensansabout{
        
        font-size: large;
        line-height: 56px;
        
    }
    
}

@media screen and (max-width: 650px) {
    .app__aboutus-overlay img {
        width: 80%;
        height: 320px;
    }

        .p__opensansabout{
        
            font-size: medium;
            
        }
}


    @media screen and (max-width: 450px) {
        .p__opensansabout{
        
            font-size: medium;
            
        }
    }